
<template>
<div>
  <div v-if="isLoading" class="loading">
    <unc-loader></unc-loader>
  </div>

  <div v-else>
    <div class="card animated fadeIn">
      <div class="card-header ch-alt">
        <h2>
          SELECCIONÁ TU ROL
          <small>Indicanos cuál es tu rol actual dentro de la Universidad.</small>
        </h2>
      </div>

      <div class="card-body card-padding">
        <div>
          <div class="radio m-b-20" v-for="rol of orderedRoles" :key="rol.code">
            <label>
              <input v-model="selectedPersonRol" type="radio" name="userName" :value="rol" />
              <i class="input-helper"></i>
              {{rol.name}}
            </label>
          </div>
          <div class="row m-t-30">
            <div class="col-md-12 text-right">
              <button @click="goBack()" type="button" class="btn btn-link waves-effect">Volver</button>
              <button @click="goNext()" class="btn btn-primary waves-effect">Siguiente</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  swal,
  HttpYULI,
} from "../../share.js";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import _ from 'lodash';


export default {
  data() {
    return {
      titulo: "ROLES",
      isLoading: true,
      emailHelpdesk: "",
      urlProfile: "",
      roles: [],
      orderedRoleNames: [],
      selectedPersonRol: ""
    };
  },
  computed: {
    ...mapState(["searchIdentifier", "currentUser"]),
    ...mapGetters(["hasUserYuli", "hasUserYuliActive", "userYuli", "users", 'configApp']),
    isManualRegister: function() {
      return this.currentUser ? false : true;
    },
    orderedRoles : function () {
      return _.orderBy(this.roles, 'code');
    }
  },
  mounted() {
    this.emailHelpdesk = this.configApp.emailHelpdesk;
    this.urlProfile = this.configApp.urlProfile || "";

    this.getRoles();
    // hardcodeo de emailRecuperation para test
    //if (this.currentUser) this.currentUser.emailRecuperation = 'esteban.u.aguero+test21@gmail.com'
  },
  methods: {
    getRoles() {
      HttpYULI.get("/v2/uncprofiles").then(res => {
        this.roles = res.data;
      }).finally(() => this.isLoading = false )
    },
    goNext() {
      this.$store.commit("setSelectedPersonRol", this.selectedPersonRol);
      if (this.selectedPersonRol == "") {
        swal({
          title: "Opción Incorrecta",
          text: "Seleccioná una opción válida.",
          type: "warning"
        });
      } else {
        if (this.currentUser)
          this.$store.commit("setCurrentUser", this.currentUser);
        this.$store.commit("setCurrentComponent", "register-person-form");
      }
    },
    goBack() {
      this.$store.dispatch("cleanCurrentUser");
      this.$store.commit("setCurrentComponent", "register-search");
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
